.footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid  !important;
}

.image {
    width: 20px;
}

@media (max-width: 420px) {
    .text {
        font-size: x-small !important;
    }
}