.navBarColor {
    /* background-image: linear-gradient(to right, rgb(255, 123, 0), rgb(34, 55, 247)); */
    background-color: white;
    border-bottom: 5px solid #38B6FF !important;
    /* border-style: solid !important;
    border-bottom-width: 5px !important; */
    /* border-top: none !important;
    border-left: none !important;
    border-right: none !important; */
}

.li:hover {
    background-color: #def0fa;
    border-radius: 5%;
}

.navbar-brand {
    margin-right: 0 !important;
}

.nav-link {
    color: darkblue !important;
}

.badge {
    background-color: #b94517;
    padding: 0.25rem 1rem;
    border-radius: 25px;
    position: relative;
    /* margin-left: 1rem; */
    font-weight: bold;
  }



@media (max-width: 385px) {
    .li {
        font-size: smaller !important;
    }
}
@media (max-width: 700px) {
    .logoblack {
        width: 25%;
    
    }
}
@media (max-width: 991px) {

    .hamburgerBadge {
        background-color: #b94517;
        padding-left: 0;
        padding: 0.1rem 0.1rem;
        font-size: small;
        border-radius: 25px;
        position: relative;
        color: white;
        font-weight: bold;
    }
}
@media (min-width: 701px) {
    .logoblack {
        width: 35%;
    }
}
@media (min-width: 992px) {
    .hamburgerBadge {
        display: none;
    }
}
