
@media (max-width: 567px) {
    .mainImage {
        width: 300px !important;
    }

    .footer {
        position: fixed !important;
    }
}

